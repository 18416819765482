<template>
  <div class="app-container">
    <div class="company-container" v-loading="listLoading">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="封面">
          <upload-one v-model="form.cover"></upload-one>
        </el-form-item>
        <el-form-item label="资质档案">
          <upload-list v-model="form.profile_imgs"></upload-list>
          <div style="font-size:12px;color:red;">营业执照、食品生产许可证、电信增值证等，可添加多张</div>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="form.company_name" placeholder="请输入公司名称" />
        </el-form-item>
        <el-form-item label="介绍">
          <el-input v-model="form.introduce" type="textarea" placeholder="请输入介绍" />
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="form.tel" placeholder="请输入联系方式" />
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址" />
        </el-form-item>
        <el-form-item v-if="user.user.type === 'admin'">
          <el-button type="primary" @click="saveData" :loading="btnLoading">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import request from "@/utils/request";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      listLoading: false,
      btnLoading: false,
      form: {},
    };
  },
  created() {
    this.getInfo()
  },
  computed: {
    ...mapGetters(["schools", "school_id","user"])
  },
  mounted() {},
  watch: {
  },
  methods: {
    getInfo() {
      this.listLoading = true;
      request({
        url: "/api/backend/company/info",
        method: "get",
      }).then(response => {
        this.listLoading = false
        this.form = response.data
      });
    },
    saveData() {
      this.btnLoading = true;
      request({
        url: "/api/backend/company/save",
        method: "post",
        data: this.form
      }).then(() => {
        this.btnLoading = false;
        this.getInfo();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.company-container {
  width: 86%;
  margin: 10px auto;;
}
</style>
